<template>
  <div>

    <DashboardHeader/>

    <v-row>
      <v-col md="12">
        <BlockStatVisitors/>
      </v-col>
    </v-row>

  </div>
</template>

<style></style>

<script>
import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

import DashboardHeader from '@/components/admin/DashboardHeader.vue'
import BlockStatVisitors from '@/components/admin/BlockStatVisitors.vue'

export default {
  name: 'admin-dashboard',
  components: { DashboardHeader, BlockStatVisitors },
  data: () => ({
    adminList: [],
    dataSet: null
  }),
  async mounted(){
    //this.fetchData()
    //this.loadAdmins()
  },
  methods: {
    fetchData(){
      this.$store.dispatch("admin/fetchEntityTypesAvailables")
    },
    async loadAdmins(){
      let res = await axios.post('/admin/query-entities', {
                        entityType: "user",
                        query: { isAdmin: true }
                      })
      if(res.data.error == false){
        this.adminList = res.data.entities
      }
    },
  },
  computed: {    
  },
  filters: {
    
  },
  watch: {
     
  }, 
}
</script>
